import axios from 'axios';

export const checkLiscence = async (username) => {
  const liscenceBaseURL = process.env.REACT_APP_LISCENCE_BASE_URL;

  const getToken = async () => {
    try {
      const response = await axios.post(`${liscenceBaseURL}/api/v1/token`, null, {
        params: {
          username: process.env.REACT_APP_USERNAME,
          password: process.env.REACT_APP_PASSWORD,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching token:', error);
      return { error: 'error' };
    }
  };

  const token = await getToken();
  if (token && token.jwt_token) {
    try {
      const encodedQueryString = encodeURIComponent(username);
      const response = await axios.get(`${liscenceBaseURL}/wp/v2/users/`, {
        params: {
          slug: encodedQueryString,
        },
        headers: {
          Authorization: `${token.token_type} ${token.jwt_token}`,
        },
      });

      if (response.data.length === 0) {
        return {
          error: 404,
          error_msg: 'License does not exist. Contact your provider.',
        };
      }

      const dateString = `${response.data[0].acf.license_end_day.substring(0, 4)}-${response.data[0].acf.license_end_day.substring(4, 6)}-${response.data[0].acf.license_end_day.substring(6, 8)}`;
      const expiration = new Date(dateString);

      if (expiration < new Date()) {
        return {
          error: 404,
          error_msg: 'License expired. Contact your provider.',
        };
      }

      if (!response.data[0].acf.WebApp) {
        return {
          error: 404,
          error_msg: 'License invalid. Contact your provider.',
        };
      }

      return { liscence: response.data[0].acf };
    } catch (error) {
      console.error('Error checking license:', error);
      return {
        error: 404,
        error_msg: 'License does not exist. Contact your provider.',
      };
    }
  }

  return { error: 404, error_msg: 'Server is down' };
};
