import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NavBar from "../Navbar/Navbar.js";
import Header from '../Header/Header.js';
import { useUser } from '../UserContext';
import * as XLSX from 'xlsx';
import axiosInstance from '../axiosConfig.js';
import './Fortotikes.css';
import Multiselect from "multiselect-react-dropdown";
import { hideLoading, showLoading } from '../store/loadingSlice.js';
import { useTranslation } from "react-i18next";

function ListaFortotikon() {
  const { t } = useTranslation();
  const [isNavOpen, setIsNavOpen] = useState(true);
  const { username, companyName } = useUser();
  const dispatch = useDispatch();
  const staticData = useSelector((state) => state.static.static);
  const licenseData = useSelector((state) => state.liscence.modules);
  const stateOptions = [
    { name: "10", id: "10" },
    { name: "20", id: "20" },
    { name: "30", id: "30" }
  ];

  const [fortotikes, setFortotikes] = useState([]);
  const [filters, setFilters] = useState({
    state: [],
    fromdate: getCurrentMonthStart(),
    todate: getCurrentMonthEnd(),
  });
  // PAGINATION
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [pagefrom, setPageFrom] = useState(1);
  const [pageto, setPageTo] = useState(20); 

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setPageFrom((prev) => prev + 20);
      setPageTo((prev) => prev + 20);
      setCurrentPage((prev) => prev + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setPageFrom((prev) => Math.max(1, prev - 20));
      setPageTo((prev) => Math.max(20, prev - 20));
      setCurrentPage((prev) => prev - 1);
    }
  };

  useEffect(() => {
    getFortotikes(pagefrom, pageto);
  }, [pagefrom, pageto]);

  function getCurrentMonthStart() {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  function getCurrentMonthEnd() {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  const formatDateForAPI = (dateStr) => {
    return dateStr.replace(/-/g, '');
  };

  const getFortotikes = async () => {
    try {
      dispatch(showLoading());

      const thisClientID = localStorage.getItem("token");
      const parameters = {
        url: `https://${licenseData.company_url}/s1services`,
        service: "SqlData",
        clientID: thisClientID,
        appId: "1001",
        SqlName: "10022",
        company: licenseData.company_id,
        fromdate: formatDateForAPI(filters.fromdate),
        todate: formatDateForAPI(filters.todate),
        afm: licenseData.afm,
        pagefrom: String(pagefrom),
        pageto: String(pageto),
      };

      const response = await axiosInstance.post("/api", parameters);
      if (response && response.data) {        
        // Assuming rowcount1 is part of each row returned
        const totalRows = response.data.rows[0]?.ROWCOUNT1 || 0;
        console.log(totalRows);
        setTotalPages(totalRows > 0 ? Math.ceil(totalRows / 20) : 1);
        console.log(response.data.rows);
        setFortotikes(response.data.rows);
        ///return response;
      }else{
        setFortotikes([]);
      }
    } catch (error) {
      console.error("Error fetching fortotikes:", error);
      alert(t("errorFetchingData"));
      return null;
    } finally {
      dispatch(hideLoading());
    }
  };
  
  useEffect(() => {
    getFortotikes();
  }, []);

  const loadFortotikes = async () => {
    const resp = await getFortotikes();
    if (resp && resp.data.success) {
      setFortotikes(resp.data.rows);  // Update fortotikes state with new data
    } else if (resp && resp.data.error) {
      alert(resp.data.error);
    }
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const handleStateSelect = (selectedList) => {
    setFilters({
      ...filters,
      state: selectedList,
    });
  };

  const handleStateRemove = (selectedList) => {
    setFilters({
      ...filters,
      state: selectedList,
    });
  };

  const applyFilters = () => {
    setPageFrom(1);
    setPageTo(20);
    setCurrentPage(1);
    loadFortotikes();
  };

  const exportToExcel = () => {
    // Get table headers
    const tableHeaders = Array.from(document.querySelectorAll('.table th')).map(
      th => th.innerText
    );
    // export excel with table titles
    const dataWithHeaders = [tableHeaders, ...fortotikes.map(row => [
      row.AA || '-',
      row.TRNDATE || '-',
      row.FORTOTIKI,
      row.PARASTATIKOPELATI || '-',
      row.APOSTOLEAS || '-',
      row.PARALIPTIS || '-',
      row.SHIPPINGADD || '-',
      row.POLI || '-',
      row.KATASTASIDIANOMIS,
      row.AITIAAKIROSIS || '-',
      row.DIANOMICOMMENT || '-',
    ])];
    const worksheet = XLSX.utils.json_to_sheet(dataWithHeaders);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Invoices');
    XLSX.writeFile(workbook, 'invoices.xlsx');
  };

  return (
    <div className={`container-fortotikes ${isNavOpen ? 'nav-open' : 'nav-closed'}`}>
      <NavBar toggleNav={toggleNav} />
      <div className="dashboard-content">
        <Header username={username} companyName={companyName} />
        <div className="main-content">
          <h1>{t("invoices")}</h1>
          <div className="filters-fortotikes">
            <label>{t("fromDate")}:</label>
            <input
              type="date"
              name="fromdate"
              value={filters.fromdate}
              onChange={handleFilterChange}
              className="filter-input"
            />
            <label>{t("toDate")}:</label>
            <input
              type="date"
              name="todate"
              value={filters.todate}
              onChange={handleFilterChange}
              className="filter-input"
            />
            <Multiselect
              options={stateOptions}
              selectedValues={filters.state}
              onSelect={handleStateSelect}
              onRemove={handleStateRemove}
              displayValue="name"
              placeholder={t("select")}
              className="filter-input muilti-selection"
            />
            <button className="filter-button" onClick={applyFilters}>{t("applyFilters")}</button>
            <button className="export-button" onClick={exportToExcel}>{t("exportToExcel")}</button>
          </div>
          <div className="table-container">
            <table className="table">
              <thead>
                <tr>
                  <th>{t("AA")}</th>
                  <th>{t("entryDate")}</th>
                  <th>{t("DocumentCode")}</th>
                  <th>{t("CustomerDoc")}</th>
                  <th>{t("SenderName")}</th>
                  <th>{t("RecieverName")}</th>
                  <th>{t("DeliveryAddress")}</th>
                  <th>{t("DeliveryCity")}</th>
                  <th>{t("DeliveryStatus")}</th>
                  <th>{t("CancellationReason")}</th>
                  <th>{t("DeliveryRemarks")}</th>
                </tr>
              </thead>
              <tbody>
                {fortotikes.map((fortotiki) => (
                  <tr key={fortotiki.findoc}>
                    <td>{fortotiki.AA || '-'}</td>
                    <td>{fortotiki.TRNDATE || '-'}</td>
                    <td>{fortotiki.FORTOTIKI}</td>
                    <td>{fortotiki.PARASTATIKOPELATI || '-'}</td>
                    <td>{fortotiki.APOSTOLEAS || '-'}</td>
                    <td>{fortotiki.PARALIPTIS || '-'}</td>
                    <td>{fortotiki.SHIPPINGADD || '-'}</td>
                    <td>{fortotiki.POLI || '-'}</td>
                    <td>{fortotiki.KATASTASIDIANOMIS}</td>
                    <td>{fortotiki.AITIAAKIROSIS || '-'}</td>
                    <td>{fortotiki.DIANOMICOMMENT || '-'}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="pagination-buttons">
              <button onClick={handlePreviousPage} disabled={currentPage === 1}>
                {t("Previous")}
              </button>
              <span className="paginationSpan">{t("Page")} {currentPage} {t("outOf")} {totalPages}</span>
              <button onClick={handleNextPage} disabled={currentPage === totalPages}>
                {t("Next")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ListaFortotikon;
