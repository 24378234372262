import React, { useState } from 'react';
import './Contact.css';
import NavBar from "../Navbar/Navbar";
import Header from '../Header/Header';
import { useUser } from '../UserContext';

function Contact() {
  const [isNavOpen, setIsNavOpen] = useState(true);
  const { username, companyName } = useUser();
  const [formData, setFormData] = useState({
    name: '',
    vat: '',
    email: '',
    phone: '',
    message: ''
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Here you would handle the form submission to send an email
    console.log('Form submitted', formData);
    // Clear the form
    setFormData({
      name: '',
      vat: '',
      email: '',
      phone: '',
      message: ''
    });
  };

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  return (
    <div className={`container-contact ${isNavOpen ? 'nav-open' : 'nav-closed'}`}>
      <NavBar toggleNav={toggleNav} />
      <div className="dashboard-content">
        <Header username={username} companyName={companyName} />
        <div className="main-content">
          <h1>Contact Us</h1>
          <form className="contact-form" onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="name">Name*</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="vat">VAT</label>
              <input
                type="text"
                id="vat"
                name="vat"
                value={formData.vat}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email*</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="phone">Phone*</label>
              <input
                type="tel"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="message">Message</label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
              ></textarea>
            </div>
            <button type="submit" className="submit-button">Submit</button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Contact;
