import React, { useRef, useState } from 'react';
import axiosInstance from '../axiosConfig';
import './UploadInvoice.css';
import NavBar from "../Navbar/Navbar";
import Header from '../Header/Header';
import { useUser } from '../UserContext';
import { useDispatch, useSelector } from 'react-redux';
import { hideLoading, showLoading } from '../store/loadingSlice';

function UploadInvoice() {
  const [isNavOpen, setIsNavOpen] = useState(true);
  const { username, companyName } = useUser();
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const [data, setData] = useState(null);
  const licenseData = useSelector((state) => state.liscence.modules);

  const clientId = process.env.REACT_APP_CLIENT_ID;
  const apiKey = process.env.REACT_APP_API_KEY;

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  // Function to fetch TRDR using VAT number from the invoice data
  const fetchSupplierTRDR = async (vat) => {
    const parameters = {
      url: `https://${licenseData.company_url}/s1services`,
      service: "getBrowserInfo",
      clientID: localStorage.getItem("token"),
      appId: "1001",
      OBJECT: "SUPPLIER",
      LIST: "",
      VERSION: 2,
      LIMIT: 1,
      FILTERS: `SUPPLIER.AFM=${vat}`
    };

    try {
      const response = await axiosInstance.post("/api", parameters);
      if (response.data.success && response.data.rows && response.data.rows.length > 0) {
        return response.data.rows[0].TRDR;
      } else {
        throw new Error("Supplier TRDR not found.");
      }
    } catch (error) {
      console.error('Error fetching TRDR:', error);
      alert('Error fetching supplier TRDR');
      return null;
    }
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append('file', file);

      try {
        dispatch(showLoading());
        const timestamp = Math.floor(Date.now() / 1000);
        const response = await axiosInstance.post('/veryfi', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        
        if (response.data.status === "fail" && response.data.message === "Not Authorized") {
          alert("Authorization failed. Please check your API credentials.");
          return; // Stop further processing if not authorized
        }

        const invoiceData = response.data;
        console.log(invoiceData);

        // Fetch TRDR using VAT from invoiceData if VAT is available
        const vatNumber = invoiceData.vendor?.vat_number;
        //|| invoiceData.bill_to?.vat_number
        let trdr = "13248"; // Default value

        if (vatNumber) {
          const fetchedTrdr = await fetchSupplierTRDR(vatNumber);
          if (fetchedTrdr) {
            trdr = fetchedTrdr; // Use fetched TRDR if available
            /// Console if TRDR is found
          }
        } else {
          console.warn("VAT number not available in invoice data.");
        }

        // Prepare transformed data
        const transformedData = {
          url: `https://${licenseData.company_url}/s1services`,
          service: "setData",
          clientID: localStorage.getItem("token"),
          appId: 1001,
          OBJECT: "PURDOC",
          KEY: "",
          data: {
            PURDOC: [
              {
                SERIES: 2062,
                CMPFINCODE: invoiceData.invoice_number || "Unknown",
                TRNDATE: invoiceData.date || "Unknown",
                TRDR: trdr, // Use either fetched TRDR or default
                PAYMENT: 1003,
                SHIPMENT: "1000",
                REMARKS: "DEMO",
                SUMAMNT: invoiceData.total || "0.00",
                line_items: (invoiceData.line_items || []).length.toString()
              }
            ],
            MTRDOC: [
              {
                SHIPPINGADDR: invoiceData.ship_to?.address || "GIANNARI 4"
              }
            ],
            ITELINES: (invoiceData.line_items || []).map((item, index) => ({
              LINENUM: index,
              SRCHCODE: item.sku || item.reference || "9999",
              QTY1: (item.quantity || 1).toString(),
              PRICE: (item.price || 0).toFixed(2),
              DISC1VAL: item.discount || "0.00",
              COMMENTS: item.text || item.description || "",
              LINEVAL: item.total 
            }))
          }
        };
        ///|| ((item.quantity || 1) * (item.price || 0)).toFixed(2)
        
        setData(transformedData);
        alert('Invoice data processed successfully!');
      } catch (error) {
        console.error('Error processing invoice:', error);
        alert('Error processing invoice!');
      } finally {
        dispatch(hideLoading());
      }
    }
  };

  const handleDataUpload = async () => {
    if (!data) {
      alert('No data to upload!');
      return;
    }

    try {
      dispatch(showLoading());
      const response = await axiosInstance.post("/api", data);
      if (response.data.success) {
        alert('Invoice uploaded successfully!');
      } else {
        alert(`Error uploading invoice: ${response.data.error}`);
      }
    } catch (error) {
      console.error('Error uploading invoice:', error);
      alert('Error uploading invoice!');
    } finally {
      dispatch(hideLoading());
    }
  };

  return (
    <div className={`container-upload ${isNavOpen ? 'nav-open' : 'nav-closed'}`}>
      <NavBar toggleNav={toggleNav} />
      <div className="dashboard-content">
        <Header username={username} companyName={companyName} />
        <div className="main-content">
          <h1>Upload PDF Invoice File</h1>
          <input
            type="file"
            className='form-control'
            accept=".pdf"
            onChange={handleFileUpload}
            ref={fileInputRef}
          />
          {data && (
            <button onClick={handleDataUpload} className="btn btn-primary m-3">
              Upload Invoice
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default UploadInvoice;
